.home-hero {
    color: #fff;
    background-image: url(https://jilikoa.com/img/index/banner-bg.jpg);
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    background-size: contain;
    padding: 50px 0 50px 0;
    overflow: hidden;
    height: auto;
    /* min-height: 100vh; */
}

.home-hero .title .hero-title {
    background: #fcf5d6;
    background: -webkit-gradient(linear, left top, left bottom, from(#fcf5d6), color-stop(47%, #f5da7f), to(#eec45e));
    background: linear-gradient(to bottom, #fcf5d6 0%, #f5da7f 47%, #eec45e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf5d6", endColorstr="#eec45e", GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
}

.hero-title {
    font-size: 3em;
    font-weight: 400;
    line-height: 1.2;
}

.title .title-advantages {
    background: #fcf5d6;
    background: -webkit-gradient(linear, left top, left bottom, from(#fcf5d6), color-stop(47%, #f5da7f), to(#eec45e));
    background: linear-gradient(to bottom, #fcf5d6 0%, #f5da7f 47%, #eec45e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf5d6", endColorstr="#eec45e", GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
}

/* advantages */
.box {
    background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#1f1f1f)) padding-box, -webkit-gradient(linear, left top, left bottom, from(#56a9d6), to(#404040)) border-box;
    background: linear-gradient(#000000, #1f1f1f) padding-box, linear-gradient(180deg, #56a9d6, #404040) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.box:hover {
    -webkit-box-shadow: 0 0 25px rgba(34, 149, 192, 0.8);
    box-shadow: 0 0 25px rgba(34, 149, 192, 0.8);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.all-height {
    height: 100%;
}

/* advantages end */

/* Favorite */
.bg-b {
    background-color: #2b2b2b;
}

.bg-b .title {

    font-family: "Manrope", sans-serif;
    background: #fcf5d6;
    background: -webkit-gradient(linear, left top, left bottom, from(#fcf5d6), color-stop(47%, #f5da7f), to(#eec45e));
    background: linear-gradient(to bottom, #fcf5d6 0%, #f5da7f 47%, #eec45e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf5d6", endColorstr="#eec45e", GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;

}

.game-fav .box {
    background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#1f1f1f)) padding-box, -webkit-gradient(linear, left top, left bottom, from(#56a9d6), to(#404040)) border-box;
    background: linear-gradient(#000000, #1f1f1f) padding-box, linear-gradient(180deg, #56a9d6, #404040) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
}

.game-fav .bg {
    background: url(https://jilikoa.com/img/index/home-game-bg.png) no-repeat left top;
    background-size: contain;

}

.game-fav .title {
    color: #99d8f9;
    font-size: 2rem;
    text-decoration: none;

}

/* Favorite end */

/* fav bg2 */
.bg-1 {
    background: url(https://jilikoa.com/img/index/home-bg01.jpg) no-repeat center top #000;
    background-size: cover;
}

/* fav bg2 end */