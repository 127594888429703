/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  font-size: 18px;
  background: #000000;
  line-height: 1.5;
  color: #fff;
}

main {
  min-height: auto;
}

section {
  min-height: 100vh;
}

.btn-download {
  padding: 10px 30px;
  text-align: center;
  margin: 2px auto;
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  background: #09a7ce;
  background: -webkit-gradient(linear, left top, left bottom, from(#23c3eb), color-stop(60%, #1595bb), to(#1b7fa1));
  background: linear-gradient(to bottom, #23c3eb 0%, #1595bb 60%, #1b7fa1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23c3eb", endColorstr="#1b7fa1", GradientType=0);
}

.bg-main {
  background: url(https://jilikoa.com/img/main-bg.jpg) no-repeat center top #000;
  background-size: cover;
}

.bg-main .title {
  background: #fcf5d6;
  background: -webkit-gradient(linear, left top, left bottom, from(#fcf5d6), color-stop(47%, #f5da7f), to(#eec45e));
  background: linear-gradient(to bottom, #fcf5d6 0%, #f5da7f 47%, #eec45e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf5d6", endColorstr="#eec45e", GradientType=0);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

.img_fit {
  max-width: 100%;
  display: block;
}

.bg-b {
  background-color: #2b2b2b;
}

.bg-b .title {
  background: #fcf5d6;
  background: -webkit-gradient(linear, left top, left bottom, from(#fcf5d6), color-stop(47%, #f5da7f), to(#eec45e));
  background: linear-gradient(to bottom, #fcf5d6 0%, #f5da7f 47%, #eec45e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf5d6", endColorstr="#eec45e", GradientType=0);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  font-size: 2rem;
}